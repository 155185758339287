<mat-drawer-container class="example-container">
  <mat-drawer position="end" #drawer mode="side">
    <div>
      <div class="text-center w-100 btn btn-primary p-4 m-0">
        Questions List
        <a class="float-end" (click)="drawer.close()">
          <svg class="bi" width="40" height="16">
            <use xlink:href="#close"></use>
          </svg>
        </a>
      </div>
    </div>

    <div class="list-group">
      <a
        *ngFor="let ques of questions; let i = index"
        class="list-group-item list-group-item-action"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ ques.speaker }}</h5>
          <small class="text-muted">{{
            ques.created_at | date: "shortTime"
          }}</small>
        </div>
        <p class="mb-1">{{ ques.question }}</p>
        <small class="text-muted">{{ ques.user }}</small>
      </a>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <!-- left side -->

    <app-side-bar></app-side-bar>

    <div class="container-fluid p-0 main-wrapper">
      <app-header></app-header>
      <div class="row">
        <div class="col-8 offset-2">
          <div class="container-fluid p-0">
            <!-- start title -->
            <div class="row">
              <div class="col-12 h2 pane-heading">
                <img src="./assets/img/logo.png" style="margin-left:10px; margin-right: 10px;" />
           
            <img src="./assets/img/concept.png" style="margin-left:10px; margin-right: 10px;" />
              </div>
              <div class="col-12 h3 mt-md-5 mt-sm-5 pane-heading ">
            <br>
                Epilepsy Reassurance Webinar
              </div>
              <div class="col-12 h4">
                Approach to the Medical treatment of Epilepsy (Challenging Cases)<br> <br>
                Tuesday, Feb 21st March, 2023 <br>8:00 pm, Bahrian/Kuwait/Qatar Time <br>9:00 pm, UAE/Oman Time
              </div>
            </div>
            <!-- End title -->
            <!-- start timer -->
            <div class="row">
              <div class="col-12 p-0">
                <app-timer></app-timer>
              </div>
            </div>
            <!-- End timer -->
            <!-- start Btn Vedio -->
            <div class="row mb-2">
              <div class="col-12 mb-2">
                <button
                  class="btn btn-primary mb-2 mr-2 pr-2"
                  style="margin-right: 10px; width: 160px"
                  type="button"
                  (click)="scroll(agenda)"
                >
                  Agenda
                </button>

                <a
                  class="btn btn-primary mb-2 mr-2"
                  (click)="openDialog('')"
                  style="margin-right: 10px; width: 160px"
                >
                  Submit a Question
                </a>

                <a
                  *ngIf="is_admin"
                  style="margin-right: 10px; width: 160px"
                  class="btn btn-primary mb-2 float-md-end"
                  (click)="drawer.toggle()"
                >
                  Question List
                </a>
               <!-- <button
                  mat-button
                  [matMenuTriggerFor]="menu"
                  class="float-sm-start float-md-end"
                  style="background-color: #ffca2c; width: 160px"
                >
                  Previous Webinars
                </button>-->
                <mat-menu #menu="matMenu">
                
                  <a mat-menu-item [routerLink]="['/show-vimeo/738638687']">
                   Epilepsy Reassurance Summit
                  </a>
                </mat-menu>
                
              </div>
            </div>
            <!-- End Btn Vedio -->
            <!-- start Vedio -->
            <div class="row mb-5">
              <div class="col-12">
                
                  <div  class="ratio ratio-16x9">
                    <iframe
                      class="embed-responsive-item"
                      [src]="'https://player.vimeo.com/video/808319946'| safeUrl"
                      frameborder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                    ></iframe>
                  
                  </div>
               
              

                <div *ngIf="live_type == 'youtube'">
                  <div *ngIf="youtube" class="ratio ratio-16x9">
                    <iframe
                      class="embed-responsive-item"
                      [src]="
                        'https://www.youtube.com/embed/' +
                          youtube +
                          '?autoplay=1&rel=0' | safeUrl
                      "
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      frameborder="0"
                      allow="autoplay"
                      allowfullscreen
                    ></iframe>
                    <div class="corner-bottom-right"></div>
                    <div class="corner-bottom-right-subscript"></div>
                    <div class="corner-top-right d-none d-lg-block"></div>
                    <div class="corner-top-left d-none d-lg-block"></div>

                    <div class="corner-top-right-mobile"></div>
                    <div class="corner-top-left-mobile"></div>
                  </div>
                </div>
              </div>
            </div>
            <!-- start row Agenda -->
            <div class="row mb-5 agenda" #agenda>
              <div class="col-12">
                <h2 class="text-center">Agenda</h2>
                <br />
                <table class="table table-striped table-hover p-3 m-2">
                  <tbody>
                    <tr>
                      <th>Time</th>
                      <th>Topic</th>
                      <th>Speaker</th>
                      <th>Duration</th>
                     
                    </tr>
                    <tr>
                      <td>9:00 PM</td>
                      <td>Opening</td>
                      <td>Mohamed El-Ezaby</td>
                      <td>5 mins</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>9:05 PM</td>
                      <td>Approach to the Medical treatment of Epilepsy 
                        (Challenging Cases)
                        </td>
                      <td>Prof. Abdul-Wahab Roman
                      </td>
                      <td>40 mins</td>
                    </tr>
    
                    <tr>
                      <td>9:45 pm</td>
                      <td>Q&A and Closing</td>
                      <td>Prof. AbdulWahab Roman/
                        Mohamed ElEzaby
                        </td>
                      <td>15 mins</td>
                    </tr>
                   
                   
                  </tbody>
                </table>
              </div>
            </div>
            <!-- End row Agenda -->


            <!-- start Biography -->
            <div class="row  mb-5">
              <div class="col-md-4 ">
                <div class="card">
                  <div class="card-header bg-transparent text-center">
                    Speaker
                  </div>
                  <img
                    src="./assets/img/abdulwahab.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title">Prof. Abdul-Wahab Roman</h5>
                    <p class="card-text">
                      Consultant Neurologist and Neurophysiologist,  <br />Mediclinic City Hospital <br/>Assistant Professor in clinical neurology at Mohammed Bin Rashid University of Medicine and Health Science (MBRU)<br />
                      Dubai, UAE
                
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header bg-transparent text-center">
                    Moderator
                  </div>
                  <img
                    src="./assets/img/ezaby2.jpg"
                    class="card-img-top"
                    alt="..."
                  />
                  <div class="card-body">
                    <h5 class="card-title">Mohamed El-Ezaby</h5>
                    <p class="card-text">
    
                      CNS - Product Manager
                      <br />
                      Hospital. Clinical Neurology Lecturer,
                      <br />
                      GSK - Gulf
                     
                      <br /><br /><br />

                    
                    </p>
                  </div>
                </div>
              </div>
             
              
            </div>
            <!-- End Biography -->
            
            <!-- start pico 
            <div class="row">
              <div class="col-md-6">
                <br />
                <div class="p-2 pt-0" style="font-size: larger">
                  <h4 class="desc-heading mb-1">Description</h4>
                  <p>
                    This webinar is designed to introduce sNPWT in high risk
                    surgeries. During this webinar the faculty will be
                    presenting surgical site infection management
                  </p>
                  <br />
                  <h4 class="desc-heading mb-1">Participant profile</h4>
                  <p>
                    Surgeons and clinical nurse specialists with an interest in
                    managing wound complications
                  </p>
                  <br />
                  <h4 class="desc-heading mb-1">Learning objectives</h4>
                  <p>
                    By attending this webinar, participants will be able to:
                  </p>
                  <ul>
                    <li>
                      Understand how to introduce sNPWT in high risk surgeries
                      to reduce surgical site complications
                    </li>
                    <li>
                      The usage for single patient use negative-pressure devices
                      in improving wound care in closed incisions
                    </li>
                  </ul>

                  <br />
                </div>
              </div>
              <div class="col-md-6" style="background-color: #fff">
                <img
                  src="assets/img/pcioname.jpeg"
                  class="img-fluid p-4"
                  style="width: 400px"
                  alt=""
                />
                <br /><br />
                <img src="assets/img/pico7.png" class="img-fluid" alt="" />
              </div>
            </div>
           End pico  -->
          </div>
        </div>
      </div>
      <div class="container pt-5 table-responsive" >
        <table class="table">
          <tbody>
            <tr>
              <td>
    
    
    Please <a href="https://medicalevents.tv/cm_ups/attachments/2022/08/16/VOFPyjsiEAt7gQcRlHK6H02U4zNG7RyYmEV7TANp.pdf">click here</a> for prescribing information.
    
    For more information, please refer to the prescribing information or contact GlaxoSmithKline via <a href="mailto:Gcc.Medinfo@gsk.com">Gcc.Medinfo@gsk.com</a>. 
    
    To report Adverse Event/s associated with the use of GSK product/s, please get in touch with us via <a href="mailto:gulf.safety@gsk.com">gulf.safety@gsk.com</a>. 
    
    All Quality complaints should be reported to the LOC Quality department mailbox box <a href="mailto:Gulf-KSA.Product-Complaints@gsk.com">Gulf-KSA.Product-Complaints@gsk.com</a>.
    
    This message is for the use of a Registered Medical Practitioner or a Hospital or a Laboratory only. 
    
    You are receiving this email as we have your details as a healthcare professional. Email programs can consider these messages as junk mail and may automatically put them in your bulk or junk folder. To ensure the emails you've requested delivered to your inbox, add our email address to your address book. Please do not reply to this message as these emails are not monitored.
    
    About your privacy: GSK may monitor our technology tools and services (including email, phone, and other communications sent to and from GSK) in order to maintain the security of systems, and to protect our staff, customers, and business partners from cyber threats and loss of information. These monitoring activities include checks for offensive materials, viruses, and other malignant code, and unauthorized or unlawful activity. 
    
    GSK monitoring is conducted with appropriate confidentiality controls and in accordance with local laws. You can learn about the information that we may process about you, and how we use your information, <a href="https://assets.gskstatic.com/pharma/GSKpro/Gulf/hcp-global-notice-11-aug-20.pdf">here</a> <br>
    You can also view our <a href="https://www.gsk.com/en-gb/locations/united-kingdom/">terms of use</a>.
    <br>
    
    Trademarks are owned by or licensed to the GSK group of companies.
    <br>
    Copyright © GlaxoSmithKline 2020. All rights reserved.
    <br>
    The following contact information must be used by healthcare professionals from UAE in addition to the GSK safety reporting email:
              </td>
            </tr>
          </tbody>
        </table>
    <div class="row align-items-center">
      <div class="col">
        <table class="table " > 
          <thead >
            <tr>
              <th scope="col" >
                Call for Reporting
      
              </th >
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Department of Pharmacovigilance & Drug Information
    Directorate General of Pharmaceutical Affairs & Drug Control
    <br>
    Ministry of Health, Sultanate of Oman
    <br>
    Phone Nos. 0096822357687 / 0096822357686
    Fax: 0096822358489
    <br>
    Email: pharma-vigil@moh.gov.om 
    <br>
    Website: www.moh.gov.om
    
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col">
        <table class="table " dir="rtl" > 
          <thead >
            <tr>
              <th scope="col" >
                الاتصال للإبلاغ
      
              </th >
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                دائرة المعلومات الدوائية
    المديرية العامة للصيدلة و الإدارة العامة
    وزارة الصحة، سلطنة عمان
    <br>
    هاتف: 0096822357687 / &nbsp; 0096822357686
    <br>
    فاكس: 0096822358489
    <br>
    البريد الالكتروني:  pharma-vigil@moh.gov.om
      <br>
    الموقع الالكتروني: www.moh.gov.om 
    <br>
    <br>
    
    
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
     
    </div>
        
      </div>
      <div class="row">
        <div class="col-12 p-0">
          <div class="footer">
            <img src="./assets/img/bottom.png" class="blob-bright img-fluid" />
          </div>
        </div>
      </div>
    </div>

    <!-- </div> -->
    <!-- end container -->
  </mat-drawer-content>
</mat-drawer-container>
