<div class="container-fluid p-0">
  <app-header></app-header>
  <div class="row mt-3">
    <div class="col-sm-12 col-md-8 offset-md-2 p-0">
      <div class="comtainer p-0 ml-5 panel">
        <div class="row m-0 mt-5">
          <div class="col-12 h3 mt-md-5 mt-sm-5 pane-heading text-center">
            <img src="./assets/img/logo.png" style="margin-left:10px; margin-right: 10px;" />
           
            <img src="./assets/img/concept.png" style="margin-left:10px; margin-right: 10px;" />
          </div>
          <div class="col-12 h2 mt-md-5 mt-sm-5 pane-heading text-center">
            Epilepsy Reassurance Webinar
          </div>
          <div class="col-md-12 h3 text-center">
            Approach to the Medical treatment of Epilepsy (Challenging Cases) <br>
       
           
          </div>
          <div class="col-md-12 h4 text-center">
            Tuesday, March 21st, 2023 <br>8:00 pm, Bahrian/Kuwait/Qatar Time<br> 9:00 pm, UAE/Oman Time
          </div>
          <!-- <div class="col-md-4 text-right h3">271d 21h 41m 37s</div> -->
        </div>
        <div class="row p-3">
          <div class="col-12 text-center">
            <app-timer></app-timer>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 offset-md-4 p-4">
            <main class="form-signin p-2">
              <form>
                <h1 class="h3 mb-3 fw-normal text-center">Please sign in</h1>
                <div
                  *ngIf="f.submitted && result?.status == 'success'"
                  class="alert alert-success"
                >
                  {{ result.message }}
                  <br />
                  Dear {{ result.user.name }}
                  <br />
                  The conference will start in 2 days
                </div>
                <div
                  *ngIf="f.submitted && result?.status == 'error'"
                  class="alert alert-danger"
                >
                  {{ result.message }}
                </div>
                <form #f="ngForm" (ngSubmit)="onSummit(f)" class="hero-form">
                  <div class="form-floating">
                    <input
                      placeholder="name@example.com"
                      id="floatingInput"
                      class="form-control form-control-name"
                      type="email"
                      #email="ngModel"
                      name="email"
                      [(ngModel)]="emailVal"
                      ngModel
                      required
                      email
                    />
                    <label for="floatingInput">Email address</label>
                  </div>
                  <div
                    *ngIf="
                      (email.invalid && email.dirty) ||
                      (f.submitted && email.invalid)
                    "
                    class="alert alert-danger"
                  >
                    <div *ngIf="email.errors!.required">Email is required</div>
                    <div *ngIf="email.errors!.email">
                      Email must be a valid email address
                    </div>
                  </div>
                  <div class="form-floating">
                    <input
                      id="floatingPassword"
                      placeholder="Password"
                      class="form-control form-control-phone"
                      name="password"
                      type="password"
                      required
                      ngModel
                      #pass="ngModel"
                      minlength="6"
                      [(ngModel)]="passwordVal"
                    />

                    <label for="floatingPassword">Password</label>
                  </div>
                  <div
                    *ngIf="
                      (pass.invalid && pass.dirty) ||
                      (f.submitted && pass.invalid)
                    "
                  >
                    <div
                      *ngIf="pass.errors!.required"
                      class="alert alert-danger"
                    >
                      Password is required
                    </div>

                    <div
                      *ngIf="pass.errors!.minlength"
                      class="alert alert-danger"
                    >
                      Password minimum length must be 6 characters
                    </div>
                  </div>
                  <div class="checkbox mb-3">
                    <label>
                      <!-- <input type="checkbox" value="remember-me" /> Remember me -->
                    </label>
                  </div>
                  <input
                    type="hidden"
                    name="conference_id"
                    ngModel="{{ conference_id }}"
                  />
                  <button class="w-100 btn btn-lg btn-primary" type="submit">
                    Sign in
                  </button>
                </form>

                <a
                  [routerLink]="['/register']"
                  class="w-100 btn btn-lg mt-2 new-req-btn"
                  style="color: #fffafa"
                >
                  New Registration</a
                >
                <!-- {{ f.value | json }} -->
              </form>
            </main>
          </div>
        </div>
        <!-- <div class="row">
              <div class="col-md-7">
                <img src="./img/pcioname.jpeg" class="img-fluid" alt="" />
                <br /><br />
                <img src="./img/pico7.png" class="img-fluid" alt="" />
              </div>
            </div> -->
      </div>
    </div>
  </div>
  <div class="container pt-5 table-responsive" >
    <table class="table">
      <tbody>
        <tr>
          <td>


Please <a href="https://medicalevents.tv/cm_ups/attachments/2022/08/16/VOFPyjsiEAt7gQcRlHK6H02U4zNG7RyYmEV7TANp.pdf">click here</a> for prescribing information.

For more information, please refer to the prescribing information or contact GlaxoSmithKline via <a href="mailto:Gcc.Medinfo@gsk.com">Gcc.Medinfo@gsk.com</a>. 

To report Adverse Event/s associated with the use of GSK product/s, please get in touch with us via <a href="mailto:gulf.safety@gsk.com">gulf.safety@gsk.com</a>. 

All Quality complaints should be reported to the LOC Quality department mailbox box <a href="mailto:Gulf-KSA.Product-Complaints@gsk.com">Gulf-KSA.Product-Complaints@gsk.com</a>.

This message is for the use of a Registered Medical Practitioner or a Hospital or a Laboratory only. 

You are receiving this email as we have your details as a healthcare professional. Email programs can consider these messages as junk mail and may automatically put them in your bulk or junk folder. To ensure the emails you've requested delivered to your inbox, add our email address to your address book. Please do not reply to this message as these emails are not monitored.

About your privacy: GSK may monitor our technology tools and services (including email, phone, and other communications sent to and from GSK) in order to maintain the security of systems, and to protect our staff, customers, and business partners from cyber threats and loss of information. These monitoring activities include checks for offensive materials, viruses, and other malignant code, and unauthorized or unlawful activity. 

GSK monitoring is conducted with appropriate confidentiality controls and in accordance with local laws. You can learn about the information that we may process about you, and how we use your information, <a href="https://assets.gskstatic.com/pharma/GSKpro/Gulf/hcp-global-notice-11-aug-20.pdf">here</a> <br>
You can also view our <a href="https://www.gsk.com/en-gb/locations/united-kingdom/">terms of use</a>.
<br>

Trademarks are owned by or licensed to the GSK group of companies.
<br>
Copyright © GlaxoSmithKline 2020. All rights reserved.
<br>
The following contact information must be used by healthcare professionals from UAE in addition to the GSK safety reporting email:
          </td>
        </tr>
      </tbody>
    </table>
<div class="row align-items-center">
  <div class="col">
    <table class="table " > 
      <thead >
        <tr>
          <th scope="col" >
            Call for Reporting
  
          </th >
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Department of Pharmacovigilance & Drug Information
Directorate General of Pharmaceutical Affairs & Drug Control
<br>
Ministry of Health, Sultanate of Oman
<br>
Phone Nos. 0096822357687 / 0096822357686
Fax: 0096822358489
<br>
Email: pharma-vigil@moh.gov.om 
<br>
Website: www.moh.gov.om

          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col">
    <table class="table " dir="rtl" > 
      <thead >
        <tr>
          <th scope="col" >
            الاتصال للإبلاغ
  
          </th >
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            دائرة المعلومات الدوائية
المديرية العامة للصيدلة و الإدارة العامة
وزارة الصحة، سلطنة عمان
<br>
هاتف: 0096822357687 / &nbsp; 0096822357686
<br>
فاكس: 0096822358489
<br>
البريد الالكتروني:  pharma-vigil@moh.gov.om
  <br>
الموقع الالكتروني: www.moh.gov.om 
<br>
<br>


          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
 
</div>
    
  </div>
  <div class="footer m">
    <img src="./assets/img/bottom.png" class="blob-bright img-fluid" />
  </div>
</div>
<!-- end container -->

<!-- JavaScript Bundle with Popper -->
<!-- <script src="js/bootstrap.bundle.min.js"></script> -->
